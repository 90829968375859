<template>
  <div>
    <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                    :curr-page.sync="pagingParams.currPage"
                    :per-page.sync="pagingParams.perPage"
                    :per-page-list="perPageList"
                    :pages="pages"
                    :search-bar-place-holder="searchBarPlaceHolder"
                    :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
    <BeeGridTable
        ref="bee"
        border
        stripe
        :showFilter="false"
        :columns="columns"
        :loading="isLoading"
        :data="rows"
        :showPager="false"
        :maxHeight="gridCardHeight"
        highlightRow
        no-filtered-data-text="データがありません。"
        :context="context"
        :span-method="customSpan"
        :showVerticalScrollBar="false"
        :showHorizontalScrollBar="false">
      <!-- HEADER ソート可能にするために 必要-->
<!--
        {title: '医療機関ID', key: 'hospitalId',slot:"hospitalId",headSlot:"hospitalNdHead"},
        {title: '医療機関名', key: 'hospitalName',slot:"hospitalName",headSlot:"hospitalNameHead"},
        {title: '住所', key: 'hospitalAddress',slot:"hospitalAddress",headSlot:"hospitalAddressHead"},
        {title: '連絡先担当者名', key: 'hosptalContactName',slot:"hosptalContactName",headSlot:"hosptalContactNameHead"},
        {title: '連絡先電話番号', key: 'hospitalContactTel',slot:"hospitalContactTel",headSlot:"hospitalContactTelHead"},
        {title: '医療関係者ID', key: 'doctorId',slot:"doctorId",headSlot:"doctorIdHead"},
        {title: '医療関係者名', key: 'doctorName',slot:"doctorName",headSlot:"doctorNameHead"},
        {title: '', key: 'after',slot:"after",headSlot:"afterHead"}
-->

      <template slot-scope="{ column,index }" slot="hospitalIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="hospitalNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="hospitalAddressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="hospitalContactNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="hospitalContactTelHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="doctorIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="doctorNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

      <!-- cell -->
      <template slot-scope="{ row,index }" slot="hospitalId"><div class="text-left">{{row.hospitalId}}</div></template>
      <template slot-scope="{ row,index }" slot="hospitalName"><div class="text-left">{{row.hospitalName}}</div></template>
      <template slot-scope="{ row,index }" slot="hospitalAddress"><div class="text-left">{{row.hospitalAddress}}</div></template>
      <template slot-scope="{ row,index }" slot="hospitalContactName"><div class="text-left">{{row.hospitalContactName}}</div></template>
      <template slot-scope="{ row,index }" slot="hospitalContactTel"><div class="text-left">{{row.hospitalContactTel}}</div></template>
      <template slot-scope="{ row,index }" slot="doctorId"><div class="text-left">{{row.doctorId}}</div></template>
      <template slot-scope="{ row,index }" slot="doctorName"><div class="text-left">{{row.doctorName}}</div></template>

      <template slot-scope="{ row,index }" slot="after">
        <div class="float-center">
          <CButton color="success py-0" size="sm" class="mr-1" @click="setHospitalWithDoctor(row)" v-if="row.doctorId">指定</CButton>
          <span v-else>医療関係者が登録されていません</span>
        </div>
      </template>
    </BeeGridTable>

  </div>

</template>

<script>



import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name:'HospitalWithDoctor',
  components:{
    MyBeeHeader, MyBeeSearchBar
  },
  mixins: [BeeMix],
  props: {
    isShow: {default: false, type: Boolean},
  },
  mounted() {
    // this.goodPaging();
  },
  data: () => {
    return {
      columns: [
        {title: '医療機関ID', key: 'hospitalId',slot:"hospitalId",headSlot:"hospitalIdHead"},
        {title: '医療機関名', key: 'hospitalName',slot:"hospitalName",headSlot:"hospitalNameHead"},
        {title: '住所', key: 'hospitalAddress',slot:"hospitalAddress",headSlot:"hospitalAddressHead"},
        {title: '連絡先担当者名', key: 'hospitalContactName',slot:"hospitalContactName",headSlot:"hospitalContactNameHead"},
        {title: '連絡先電話番号', key: 'hospitalContactTel',slot:"hospitalContactTel",headSlot:"hospitalContactTelHead"},
        {title: '医療関係者ID', key: 'doctorId',slot:"doctorId",headSlot:"doctorIdHead"},
        {title: '医療関係者名', key: 'doctorName',slot:"doctorName",headSlot:"doctorNameHead"},
        {title: ' ', key: 'after',slot:"after",headSlot:"afterHead"}
      ],
      searchBarPlaceHolder:"医療機関名、医療関係者名で検索",
      isCollapsed: true,
      searchInit:false,
    };
  },
  methods: {
    // addDoctor:function(row){
    //   this.$emit("addDoctor",row);
    // },
    // filterDoctor:function(row){
    //   this.$emit("filterDoctor",row);
    // },
    setHospitalWithDoctor: function (row) {
      this.$emit("setHospitalWithDoctor", row);
    },
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/hospital/searchHospitalWithDoctorPaging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();

        if (this.isRole("PROVIDER") && this.rows == 0) {
          this.$swal("現状、登録・編集可能な医療機関はありません。");
        }

      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }
  },
  watch: {
    isShow: function () {
      if (this.isShow) {
        console.log("this.isShow",this.isShow)
        this.goodPaging();
      }
    }
  }
}

</script>
